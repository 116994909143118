import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/g4baZdnO2zQ">
    <SEO title="Christian and Politics - Romans: Practice" />
  </Layout>
)

export default SermonPost
